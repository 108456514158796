import { default as backup_indexeFgX9XyMpkMeta } from "/opt/buildhome/repo/pages/[...slug]/backup_index.vue?macro=true";
import { default as bio_indexVYBarVHHp8Meta } from "/opt/buildhome/repo/pages/[...slug]/bio_index.vue?macro=true";
import { default as indexTFN7Ot46PXMeta } from "/opt/buildhome/repo/pages/[...slug]/index.vue?macro=true";
import { default as editYNIXVjuEGHMeta } from "/opt/buildhome/repo/pages/communities/[slug]/edit.vue?macro=true";
import { default as editAt5wGvH4tSMeta } from "/opt/buildhome/repo/pages/communities/[slug]/events/[event-slug]/edit.vue?macro=true";
import { default as indexlGGxgYxMM3Meta } from "/opt/buildhome/repo/pages/communities/[slug]/events/[event-slug]/index.vue?macro=true";
import { default as createEFk3nm9In1Meta } from "/opt/buildhome/repo/pages/communities/[slug]/events/create.vue?macro=true";
import { default as indexd5lxNOuypTMeta } from "/opt/buildhome/repo/pages/communities/[slug]/events/index.vue?macro=true";
import { default as indexGaGLmR9G1kMeta } from "/opt/buildhome/repo/pages/communities/[slug]/index.vue?macro=true";
import { default as create5ZFxUUc7QnMeta } from "/opt/buildhome/repo/pages/communities/create.vue?macro=true";
import { default as indexh8JW7RB3bVMeta } from "/opt/buildhome/repo/pages/communities/index.vue?macro=true";
import { default as get_45startedHcoLFkSECkMeta } from "/opt/buildhome/repo/pages/get-started.vue?macro=true";
import { default as indexkUOxkEycS8Meta } from "/opt/buildhome/repo/pages/home/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as sign_45incExpy2WlpQMeta } from "/opt/buildhome/repo/pages/sign-in.vue?macro=true";
import { default as sign_45upDLoukKzOegMeta } from "/opt/buildhome/repo/pages/sign-up.vue?macro=true";
export default [
  {
    name: "slug-backup_index",
    path: "/:slug(.*)*/backup_index",
    component: () => import("/opt/buildhome/repo/pages/[...slug]/backup_index.vue")
  },
  {
    name: "slug-bio_index",
    path: "/:slug(.*)*/bio_index",
    component: () => import("/opt/buildhome/repo/pages/[...slug]/bio_index.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/[...slug]/index.vue")
  },
  {
    name: "communities-slug-edit",
    path: "/communities/:slug()/edit",
    meta: editYNIXVjuEGHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/edit.vue")
  },
  {
    name: "communities-slug-events-eventslug-edit",
    path: "/communities/:slug()/events/:eventslug()/edit",
    meta: editAt5wGvH4tSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/events/[event-slug]/edit.vue")
  },
  {
    name: "communities-slug-events-eventslug",
    path: "/communities/:slug()/events/:eventslug()",
    meta: indexlGGxgYxMM3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/events/[event-slug]/index.vue")
  },
  {
    name: "communities-slug-events-create",
    path: "/communities/:slug()/events/create",
    meta: createEFk3nm9In1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/events/create.vue")
  },
  {
    name: "communities-slug-events",
    path: "/communities/:slug()/events",
    meta: indexd5lxNOuypTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/events/index.vue")
  },
  {
    name: "communities-slug",
    path: "/communities/:slug()",
    meta: indexGaGLmR9G1kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/[slug]/index.vue")
  },
  {
    name: "communities-create",
    path: "/communities/create",
    component: () => import("/opt/buildhome/repo/pages/communities/create.vue")
  },
  {
    name: "communities",
    path: "/communities",
    meta: indexh8JW7RB3bVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/communities/index.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    component: () => import("/opt/buildhome/repo/pages/get-started.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: indexkUOxkEycS8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/home/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/opt/buildhome/repo/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue")
  }
]